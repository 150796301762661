.containerProfile {
  position: relative;
  /* width: 100%;
  height: 100vh;  */
  /* border: 2px solid red; */
}
.background-imageProfile {
  /* border: 2px solid rgb(28, 189, 100); */
  position: relative;
  background-size: cover;
  background-position: center;
}

#EditPenContainer {
  /* border: 1px solid rgb(25, 194, 236); */
  background-color: rgb(25, 194, 236);
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  /* font-size: medium; */

  font-size: 15px;
  color: white;
  /* color: rgb(25, 194, 236) !important; */
  top: 40%;
  right: -18%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.avatarProfile {
  background-size: cover; /* Ensure the background image covers the entire area */
  background-position: center; /* Center the background image */
}
