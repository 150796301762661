.horizontal-scroller {
  overflow-x: auto;
  white-space: nowrap;
}

.scroll-container {
  display: inline-flex;
}
.crouselContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0.5rem !important;
  border-radius: 10px !important;
  background-color: lightgray !important;
  margin-left: 50px !important;
  margin-right: 50px !important;
  font-size: 12px !important;
  overflow: hidden !important;
  min-height: 75px !important;
  min-width: 120px !important;
  width: fit-content !important;
  block-size: fit-content !important;
  max-height: 80px !important;
  max-width: 120px !important;
  text-wrap: wrap !important;
  text-align: center !important;
  letter-spacing: 0.8px !important;
  text-transform: uppercase !important;
  background: "rgb(23,164,193)" !important;
  font-weight: bold !important;
}
.customButton {
  border: 5px solid red !important;
  background-color: blueviolet;
}
