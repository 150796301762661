.image-container {
  position: relative;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content img {
  max-width: 100vw;
  max-height: 100vh;
}

.modal-content img:hover {
  cursor: pointer;
}
