/* styles.css */
.container {
  max-width: "100%";
  margin: 0 auto;
  padding: 1rem;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}
.title {
  /* border: 2px solid red; */
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
